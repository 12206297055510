<template>
  <div class="service-users home-container">
    <div class="service-users__title">服务用户</div>
    <div ref="content" class="service-users__content">
      <div ref="contentMain" class="service-users__content__main">
        <Media @resize="handleResize">
          <div>
            <div
              v-for="item in 6"
              class="service-users__item"
              :key="item">
              <img :src="require(`@/assets/home/service-users/${item}.png`)" alt="">
            </div>
          </div>
          <template v-slot:mobile>
            <div class="service-users__item">
              <img src="@/assets/home/service-users/service-users.png" alt="">
              <!-- <Swiper
                ref="mySwiper"
                :options="swiperOptions">
                <SwiperSlide v-for="(item) in 4" :key="item">
                  <div class="swiper-item-container">
                    <template v-for="subItem in (item * 6)">
                      <div
                        v-if="(subItem > (item * 6) - 6)"
                        class="service-users__item"
                        :key="subItem">
                        <img :src="require(`@/assets/home/service-users/${subItem}.png`)" alt="">
                      </div>
                    </template>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div class="control">
                <div
                  v-for="(item, index) in 4"
                  class="control__item"
                  @click="handleSwiperSlideTo(index)"
                  :class="{
                    active: index === activeIndex
                  }"
                  :key="item">
                </div>
              </div> -->
            </div>
          </template>
        </Media>
      </div>
    </div>
    <div class="service-users__title cooperative-partner">合作伙伴</div>
    <div class="service-users__content cooperative-partner">
      <div class="service-users__content__main cooperative-partner">
        <div
          v-for="item in 5"
          class="service-users__item cooperative-partner"
          :key="item">
          <img :src="require(`@/assets/home/cooperative-partner/${item}.png`)" alt="">
        </div>
      </div>
    </div>
    <!-- <div
      class="service-users__more"
      @click="handleShowMore">
      <img
        src="@/assets/home/more1.png"
        :class="{
          'show-more': isShowMore
        }"
        alt="">
    </div> -->
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      isShowMore: false,
      activeIndex: 0,
      swiperOptions: {
      }
    }
  },
  methods: {
    handleShowMore () {
      this.$nextTick(() => {
        this.isShowMore = !this.isShowMore
        const { content, contentMain } = this.$refs
        const height = this.isShowMore
          ? (contentMain.getBoundingClientRect().height + 7) + 'px'
          : ''
        content.style.height = height
      })
    },
    handleResize (screen) {
      if (screen.type === 'mobile') {
        this.initialSwiperEvents()
      }
    },
    initialSwiperEvents () {
      this.$nextTick(() => {
        const swiper = this.$refs.mySwiper.$swiper
        swiper.on('slideChange', this.slideChange)
      })
    },
    slideChange () {
      const swiper = this.$refs.mySwiper.$swiper
      this.activeIndex = swiper.activeIndex
    },
    handleSwiperSlideTo (index) {
      const swiper = this.$refs.mySwiper.$swiper
      swiper.slideTo(index, 500, false)
    }
  }
}
</script>
<style lang="scss" scoped>
.service-users {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-users__title {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  color: #666666;
  text-align: center;
  margin-bottom: px2rem(46);

  &.cooperative-partner {
    margin-top: 100px;
  }

  @media screen and (max-width: 750px) {
    font-size: 60px;
    font-weight: 600;
    font-family: Source Han Sans CN;
    letter-spacing: 2px;
    line-height: 1;
    text-align: center;
    color: rgba(83, 83, 83, 1);
    padding-bottom: 82px;
    margin-bottom: 86px;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 200px;
      height: 20px;
      border-radius: 10px;
      background: $themeColor;
    }
  }
}
.service-users__content {

  &.cooperative-partner {
    width: 100%;
  }
  // height: px2rem(155);
  // overflow: hidden;
  transition: all .4s linear;

  @media screen and (max-width: 750px) {
    width: 100%;
    font-size: 60px;
    font-weight: 600;
    font-family: Source Han Sans CN;
    letter-spacing: 2px;
    line-height: 1;
    text-align: center;
    color: rgba(83, 83, 83, 1);
  }
}
.service-users__content__main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 750px) {
    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
.service-users__item {
  width: 167px;
  height: 48px;
  margin-left: 20px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.12);
  border-radius: 6px 6px 6px 6px;
  font-size: 0;
  overflow: hidden;

  &.cooperative-partner {
    width: 200px;
    height: 54px;

    @media screen and (max-width: 750px) {
      width: 338px;
      height: 91px;

      &:nth-of-type(4n) {
        margin-left: 214px;
      }
      &:nth-of-type(5n) {
        margin-right: 214px;
      }

      &:nth-of-type(n+4) {
        margin-top: 69px;
      }
    }
  }

  &:nth-of-type(6n) {
    margin-left: 20px;
  }
  &:nth-of-type(n + 7) {
    margin-top: px2rem(36);
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    border-radius: 0px 0px 0px 0px;
    // &:nth-of-type(5n+1) {
    //   margin-left: 0;
    // }
    // &:nth-of-type(n + 4) {
    //   margin-top: px2rem(69);
    // }
    // &:nth-of-type(6n) {
    //   margin-left: 0;
    // }
  }

  @media screen and (min-width: 751px) {
    // &:nth-of-type(6n+1) {
    //   margin-left: 0;
    // }

  }

  img {
    width: 100%;
    height: 100%;
  }
}
.service-users__more {
  display: inline-block;
  padding: px2rem(16) px2rem(20);
  background: #FD8808;
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 12px;
  font-size: 0;
  margin-top: px2rem(50);

  @media screen and (max-width: 750px) {
    display: none;
  }

  img {
    width: px2rem(42);
    height: px2rem(28);
    transition: all .4s linear;

    &.show-more {
      transform: rotate(180deg);
    }
  }
}
.service-users-mobile {
  width: 100%;

  .swiper-item-container {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .control {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .control__item  {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: rgba($color: #FD8808, $alpha: 0.5);

      &:nth-of-type(n+1) {
        margin-left: 23px;
      }
      &.active {
        background: #FD8808;
      }
    }
  }
}
</style>
