<template>
  <div class="solution home-container">
    <div class="solution__title">行业解决方案</div>
    <Media @resize="handleResize">
      <div class="solution__carousel">
        <div class="solution__carousel__pagination">
          <div
            v-for="(item, index) in list"
            class="item"
            :class="{
              active: index === activeIndex
            }"
            @click="handleSwiperSlideTo(index)"
            :key="item.name">
            {{ item.name }}
          </div>
        </div>
        <div class="solution__carousel__swiper">
          <Swiper
            ref="mySwiper"
            :options="swiperOptions">
            <SwiperSlide v-for="item in list" :key="item.name">
              <img
                @click="$router.push({name:item.routeName})"
                :src="item.img.pc"
                alt="">
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <template v-slot:mobile>
        <div class="solution__mobile">
          <div
            v-for="item in list"
            class="solution__mobile__item"
            :key="item.name">
            <img
              class="preview"
              @click="$router.push({name:item.routeName})"
              :src="item.img.mobile"
              alt="" />
            <div class="bottom">
              <div class="title">{{ item.name }}</div>
              <img
                class="arrow"
                src="@/assets/home/arrow.png"
                alt="" />
            </div>
          </div>
        </div>
      </template>
    </Media>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import solution1 from '@/assets/home/solution/1.png'
import solution2 from '@/assets/home/solution/2.png'
import solution3 from '@/assets/home/solution/3.png'
import solution4 from '@/assets/home/solution/4.png'

import mobileSolution1 from '@/assets/home/mobile/solution1.png'
import mobileSolution2 from '@/assets/home/mobile/solution2.png'
import mobileSolution3 from '@/assets/home/mobile/solution3.png'
import mobileSolution4 from '@/assets/home/mobile/solution4.png'

const getSwiperHeight = () => document.documentElement.clientWidth * (481.67 / 1920)

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      list: [
        {
          name: '政府政务',
          routeName: 'GovernmentAffairs',
          img: {
            pc: solution1,
            mobile: mobileSolution1
          }
        },
        {
          name: '金融机构',
          routeName: 'Finance',
          img: {
            pc: solution2,
            mobile: mobileSolution2
          }
        },
        {
          name: '央企国企',
          routeName: 'Operator',
          img: {
            pc: solution3,
            mobile: mobileSolution3
          }
        },
        {
          name: '企业用户',
          routeName: 'IndustryInstitutions',
          img: {
            pc: solution4,
            mobile: mobileSolution4
          }
        }
      ],
      activeIndex: 0,
      swiperOptions: {
        direction: 'vertical',
        height: getSwiperHeight()
      }
    }
  },
  methods: {
    handleResize (screen) {
      if (screen.type === 'pc') {
        this.initialSwiperEvents()
      }
    },
    initialSwiperEvents () {
      this.$nextTick(() => {
        const swiper = this.$refs.mySwiper.$swiper
        swiper.on('resize', this.resize)
        swiper.on('slideChange', this.slideChange)
      })
    },
    resize () {
      const swiper = this.$refs.mySwiper.$swiper
      const height = getSwiperHeight()
      swiper.params.height = height
    },
    slideChange () {
      const swiper = this.$refs.mySwiper.$swiper
      this.activeIndex = swiper.activeIndex
    },
    handleSwiperSlideTo (index) {
      const swiper = this.$refs.mySwiper.$swiper
      swiper.slideTo(index, 500, false)
    }
  }
}
</script>
<style lang="scss" scoped>
.solution__title {
  position: relative;
  display: none;
  font-size: 78px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 82px;
  color: rgba(102, 102, 102, 1);
  margin-bottom: 80px;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 200px;
    height: 20px;
    border-radius: 10px;
    background: $themeColor;
  }

  @media screen and (max-width: 750px) {
    display: block;
    font-size: 60px;
    font-weight: 600;
    font-family: Source Han Sans CN;
    letter-spacing: 2px;
    line-height: 1;
    text-align: center;
    color: rgba(83, 83, 83, 1);
  }
}
.solution__carousel {
  display: flex;
  height: calc(100vw * (481.67/1920));
  border-radius: 12px;
  overflow: hidden;
}
.solution__carousel__pagination {
  width: 237px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #666;
    width: 100%;
    height: calc(100vw * ((481.67/1920) / 4));
    background-color: #fff;
    cursor: pointer;

    &.active {
      color: #fff;
      background: #FD8808;
    }
  }
}
.solution__carousel__swiper {
  flex: 1;
}
.swiper-container {

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.solution__mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // margin: px2rem(30) px2rem(84) px2rem(134);
}
.solution__mobile__item {
  position: relative;
  width: 563px;
  height: 346px;
  background: #FFFFFF;
  border-radius: 13px;
  overflow: hidden;
  box-shadow: 0px 9.73px 38.94px 0px rgba(0, 0, 0, 0.1);

  &:nth-of-type(n+3) {
    margin-top: px2rem(33);
  }

  .preview {
    width: 100%;
    height: 100%;
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    padding: 0 36px;
    background: rgba(255, 255, 255, 1);

    .title {
      font-size: px2rem(36);
      font-weight: 500;
      color: rgba(102, 102, 102, 1);
    }

    .arrow {
      width: px2rem(28.52);
    }
  }
}
</style>
