<template>
  <div class="carousel">
    <div class="banner">
      <Media>
        <img
          style="opacity: 0;"
          src="@/assets/home/banner/banner-bg.png"
          alt="">
        <template v-slot:mobile>
          <img
            style="opacity: 1;"
            src="@/assets/home/banner/banner-bg-mobile.png"
            alt="">
        </template>
      </Media>
      <div
        v-for="(item, index) of list"
        :key="index"
        class="swiper-item-container"
        :class="{
          active: index === activeIndex
        }"
        @click="$router.push({name: item.name})">
        <Media>
          <img
            class="greyback"
            :class="`greyback-${index}`"
            src="@/assets/home/banner/greyback.png"
            alt="">
          <template v-slot:mobile>
            <img
              class="greyback"
              :class="`greyback-${index}`"
              src="@/assets/home/banner/greyback-mobile.png"
              alt="">
          </template>
        </Media>
        <Media>
          <img
            class="activebg"
            :class="`activebg-${index}`"
            :src="item.img.pc"
            alt="">
          <template v-slot:mobile>
            <img
              class="activebg"
              :class="`activebg-${index}`"
              :src="item.img.mobile"
              alt="">
          </template>
        </Media>
        <div class="swiper-item-title animate__animated" :class="`index-${index}`">
          <p v-for="text in item.title" :key="text">{{ text }}</p>
        </div>
        <div class="swiper-item-btn animate__animated" :class="`index-${index}`">{{ item.btnText }}</div>
      </div>
    </div>
    <div class="control">
      <div
        v-for="(item, index) in list"
        class="control__item"
        @mousemove="handleSwiperSlideTo(index)"
        :class="{
          active: index === activeIndex
        }"
        :key="item.name">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import iGuardHorizontal from '@/assets/home/banner/iGuard-horizontal.png'
import iFlowHorizontal from '@/assets/home/banner/iFlow-horizontal.png'
import iWallHorizontal from '@/assets/home/banner/iWall-horizontal.png'
import iGuardVertical from '@/assets/home/banner/iGuard-mobile.png'
import iFlowVertical from '@/assets/home/banner/iFlow-mobile.png'
import iWallVertical from '@/assets/home/banner/iWall-mobile.png'
export default {
  data () {
    return {
      list: [
        {
          name: 'iGuard',
          img: {
            pc: iGuardHorizontal,
            mobile: iGuardVertical
          },
          title: [
            '应对攻防新挑战 适配新环境',
            '新一代网页防篡改软件'
          ],
          btnText: '了解iGuard'
        },
        {
          name: 'iWall',
          img: {
            pc: iWallHorizontal,
            mobile: iWallVertical
          },
          title: [
            '基于高强度攻防对抗场景打造',
            '下一代Web应用防火墙'
          ],
          btnText: '了解iWall'
        },
        {
          name: 'iFlow',
          img: {
            pc: iFlowHorizontal,
            mobile: iFlowVertical
          },
          title: [
            '创新的',
            'Web应用漏洞应急响应平台'
          ],
          btnText: '了解iFlow'
        }
      ],
      oldIndex: 0,
      activeIndex: 0,
      swiperOptions: {
        effect: 'fade'
      }
    }
  },
  mounted () {
    this.handleSwiperSlideTo(0)
  },
  methods: {
    initialSwiperEvents () {
      this.$nextTick(() => {
        this.handleAnimate(this.activeIndex, 'add')
        this.handleTweenMaxTo(this.activeIndex, [0, 1])
        this.swiper.on('slideChange', this.slideChange)
        this.swiper.on('transitionEnd', this.slideChangeTransitionEnd)
      })
    },
    slideChange () {
      this.activeIndex = this.swiper.activeIndex
      this.list.forEach((_, index) => {
        if (this.activeIndex !== index) {
          this.handleAnimate(index, 'remove')
        }
      })
      this.handleAnimate(this.activeIndex, 'add')

      this.list.forEach((_, index) => {
        if (this.oldIndex !== index) {
          TweenMax.to(`.greyback-${index}`, 0, { opacity: 1 })
          TweenMax.to(`.activebg-${index}`, 0, { opacity: 0 })
        } else {
          TweenMax.to(`.greyback-${index}`, 0.5, { opacity: 0 })
          TweenMax.to(`.activebg-${index}`, 0.5, { opacity: 1 })
        }
      })
    },
    slideChangeTransitionEnd () {
      // this.handleTweenMaxTo(index, [0, 0])
      this.handleTweenMaxTo(this.activeIndex, [0, 1])
      // this.list.forEach((_, index) => {
      //   if (index === this.activeIndex) return
      //   this.handleTweenMaxTo(index, [1, 0])
      // })
    },
    handleSwiperSlideTo (index) {
      this.oldIndex = this.activeIndex
      this.activeIndex = index

      this.list.forEach((_, index) => {
        if (this.activeIndex !== index) {
          this.handleAnimate(index, 'remove')
        }
      })
      this.handleAnimate(this.activeIndex, 'add')
      // this.swiper.slideTo(index, 500, false)
    },
    handleTweenMaxTo (index, vars) {
      TweenMax.to(`.greyback-${index}`, 1, { opacity: vars[0] })
      TweenMax.to(`.activebg-${index}`, 1, { opacity: vars[1] })
    },
    handleAnimate (index, fn) {
      document.querySelector(`.swiper-item-title.index-${index}`)
        .classList[fn]('animate__fadeInDown', 'active')
      document.querySelector(`.swiper-item-btn.index-${index}`)
        .classList[fn]('animate__fadeInUp', 'active')
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-item-container {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s ease-out;

  &.active {
    z-index: 999;
    opacity: 1;

    .greyback {
      opacity: 0;
    }
    .activebg {
      opacity: 1;
    }
  }

  .greyback,
  .activebg {
    position: absolute;
    right: 254px;
    bottom: 40px;
    width: 867px !important;
    opacity: 0;
    transition: all 0.5s ease-in;

    @media screen and (max-width: 750px) {
      right: 32px;
      bottom: 244px;
      width: 1108px !important;
    }
  }
  .greyback {
    opacity: 1;
  }
  .swiper-item-title {
    position: absolute;
    left: 288px;
    top: 100px;
    font-size: 40px;
    line-height: 60px;
    color: #666666;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    @media screen and (max-width: 750px) {
      left: 86px;
      top: 170px;
      font-size: 66px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 99px;
    }
  }
  .swiper-item-btn {
    position: absolute;
    left: 288px;
    top: 310px;
    display: inline-block;
    padding: 14px 50px;
    background: linear-gradient(180deg, #FD964F 0%, #FC6711 100%);
    border-radius: 22px 22px 22px 22px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    @media screen and (max-width: 750px) {
      left: 86px;
      top: 507px;
      font-size: 32px;
      font-weight: 500;
      color: #FAFAFA;
      padding: 18px 50px;
      background: transparent;
      border-radius: 36px;
      border: 5px solid #FFFFFF;
    }
  }
}
.carousel {
  position: relative;
  background-color: #F2F2F2;

  .banner {
    position: relative;
    font-size: 0;
    width: 100%;
    background: url('~@/assets/home/banner/banner-bg.png');
    background-size: cover;

    @media screen and (max-width: 750px) {
      background: url('~@/assets/home/banner/banner-bg-mobile.png');
    }

    img {
      width: 100%;
    }
  }

  .control {
    display: flex;
    align-items: center;
    width: 100%;
    height: 117px;
    background: url('~@/assets/home/control-banner.png');
    background-size: cover;
    padding: 0 241px;
    box-shadow: 6px -3px 6px 1px rgba(0,0,0,0.1);

    @media screen and (max-width: 750px) {
      position: absolute;
      left: 0;
      bottom: 60px;
      z-index: 999;
      background: transparent;
      box-shadow: none;
    }
  }

  .control__item {
    position: relative;
    flex: 1;
    text-align: center;
    font-size: 20px;
    line-height: 1.8;
    font-weight: 400;
    cursor: pointer;
    color: rgba(149, 149, 149, 1);
    border-bottom: 2px solid rgba(149, 149, 149, 1);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 4px;
      background-color: transparent;
    }

    @media screen and (max-width: 750px) {
      color: #fff;
      font-size: 39px;
      padding-bottom: 5px;
      border-bottom: 5px solid #fff;
    }

    &.active {
      font-weight: 700;
      color: rgba(255, 126, 21, 1);
      border-color: rgba(255, 126, 21, 1);

      &::after {
        background-color: rgba(255, 126, 21, 1);
      }

      @media screen and (max-width: 750px) {
        color: #fff;
        border-color: #fff;

        &::after {
          bottom: -4px;
          height: 8px;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
