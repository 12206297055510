<template>
  <div class="information home-container">
    <div class="information__title">
      资讯
      <div class="information__more" @click="$router.push('Information')">
        更多
        <img src="@/assets/ico_right-arrow.png" alt="">
      </div>
    </div>
    <div class="information__content">
      <div
        v-for="item in list"
        class="information__content__item"
        :key="item.title"
        @click="handleGotoLink(item.link)">
        <img :src="item.img" alt="">
        <div class="bottom">
          <div class="bottom__main">
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <div class="date">{{ item.date }}</div>
          </div>
          <img
            src="@/assets/home/arrow.png"
            alt=""
            class="arrow">
        </div>
      </div>
      <div class="information__more mobile" @click="$router.push('Information')">
        更多
        <img src="@/assets/ico_right-arrow.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { getHomePageRealtimeInfo } from '@/data/realtime-info'
export default {
  data () {
    return {
      list: getHomePageRealtimeInfo()
    }
  },
  methods: {
    handleGotoLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.information {
  position: relative;
  // @media screen and (max-width: 750px) {
  //   padding-bottom: px2rem(240);
  //   margin: px2rem(134) px2rem(76) 0;
  // }
}
.information__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 43px;

  @media screen and (max-width: 750px) {
    position: relative;
    font-size: 60px;
    font-weight: 600;
    font-family: Source Han Sans CN;
    letter-spacing: 2px;
    line-height: 1;
    text-align: center;
    color: rgba(83, 83, 83, 1);
    padding-bottom: 82px;
    margin-bottom: 80px;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 200px;
      height: 20px;
      border-radius: 10px;
      background: $themeColor;
    }
  }
}
.information__more {
  @include horVerCenter;
  font-size: 16px;
  font-weight: 700;
  color: $themeColor;
  padding-top: 15px;
  cursor: pointer;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 750px) {
    display: none;

    &.mobile {
      display: block;
      text-align: right;
      padding-top: 36px;
      font-size: 44px;

      img {
        width: 40px;
        height: 30px;
      }
    }
  }

  img {
    width: px2rem(12);
    height: px2rem(12);
  }
}
.information__content {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}
.information__content__item {
  position: relative;
  width: 360px;
  height: 250px;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 750px) {
    width: 100%;
    font-weight: 500;

    .bottom {
      position: absolute;
      padding-left: px2rem(45) !important;
      padding-right: px2rem(65) !important;
      .title {
        font-size: 46px !important;
      }
      .arrow {
        position: absolute;
        right: 59px;
        width: 25px !important;
      }
      .date {
        font-size: 39px !important;
      }
    }

    &:nth-of-type(1) {
      height: auto;

      .bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 97px;

        .date {
          display: none;
        }

        .arrow {
          display: block;
        }
      }
    }

    &:nth-of-type(n + 2) {
      height: px2rem(211);
      margin-top: px2rem(50);

      & > img {
        display: none;
      }

      .bottom {
        position: initial;
        width: 100%;
        height: 100%;
        justify-content: space-between;

        .date {
          display: block !important;
          font-size: px2rem(30);
          font-weight: 400;
          padding-top: px2rem(23);
          color: rgba(102, 102, 102, 1);
        }

        .arrow {
          display: block;
        }
      }
    }
  }

  & > img {
    width: 100%;
    height: 70%;
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    color: #666;
    border-radius: 0 0 13px 13px;
    background: #FFFFFF;
    display: flex;
    padding-left: px2rem(20);
    padding-right: 20px;
    align-items: center;
    backdrop-filter: blur(24px);

    .bottom__main {
      width: 100%;

      @media screen and (max-width: 750px) {
        width: 100%;
        padding-right: 40px;
      }

      .date {
        font-size: 14px;
        letter-spacing: 2px;
        // display: none;
      }
    }

    .title {
      width: 100%;

      & > p {
        @include ellipsis;
      }
      font-size: px2rem(16);
      letter-spacing: 1px;
      line-height: 1.6;
      margin-bottom: 4px;
      color: rgba(102, 102, 102, 1);
    }

    .arrow {
      width: px2rem(41);
      display: none;
    }
  }
}
</style>
