<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />
    <!-- 轮播图 -->
    <Carousel />
    <!-- 产品概述 -->
    <ProductOverview />
    <!-- 解决方案 -->
    <Solution />
    <!-- 服务用户 -->
    <ServiceUsers />
    <!-- 合作伙伴 -->
    <!-- <CooperativePartner /> -->
    <!-- 资讯 -->
    <Information class="page-last" />
    <!-- 底部 -->
    <Footer :gray-theme="false" />
  </div>
</template>
<script>
import Carousel from './components/carousel'
import ProductOverview from './components/product-overview'
import Solution from './components/solution'
import ServiceUsers from './components/service-users'
import Information from './components/information'

export default {
  components: {
    Carousel,
    ProductOverview,
    Solution,
    ServiceUsers,
    Information
  }
}
</script>
